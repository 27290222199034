import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
// Components
import { Seo, EnrollmentForm } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'

const EnrollmentFormPage = () => {
  const { userType, isAuthLoading, isAuthenticated } = useAuthContext()
  const { prismicData }: any = usePrismic()

  const { title } = prismicData.prismicHomepage

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert('It looks like you are already an ambassador')
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])
  // TODO change prismic page
  return (
    <>
      <Seo title={title[0].text} />
      <EnrollmentForm />
    </>
  )
}

export default EnrollmentFormPage
